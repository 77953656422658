import React from 'react';
import EmailSubscription from './components/emailsub/emailsub';


function App() {
  return (
    <>
     <EmailSubscription />
    </>
  );
}

export default App;
